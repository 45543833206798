<template>
  <div>
    <v-row class="neumorphism-flat px-5 pb-3  pb-0 notification-type-card">
      <v-col cols="12" class="pb-0">
        <div class="notification-type-card-title">
          Notification Type Analysis:
        </div>
        <template v-if="!is_content_loading">
          <div v-if="series[0]">
            <div id="chart">
              <apexchart
                type="area"
                height="160"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </div>
          <div v-else class="notification-type-card-sub-title py-5 pl-5">
            No Data Available
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <v-progress-circular
              class="my-5"
              :size="80"
              color="#008ffb"
              width="8"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      series: [
        {
          name: "Bulk",
          data: [],
        },
        {
          name: "Individual",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      spline_data: "notification/getSplineData",
      is_loading: "notification/getIsLoading",
    }),
    spline_graph_data() {
      return this.spline_data;
    },
    is_content_loading() {
      return this.is_loading;
    },
  },
  created() {
    this.assignValues();
  },
  watch: {
    is_content_loading() {
      this.assignValues();
    },
  },
  methods: {
    assignValues() {
      if (this.spline_graph_data.bulk_spline_data) {
        this.series[0].data = [];
        this.series[1].data = [];
        this.chartOptions.xaxis.categories = [];
        let bulk_spline_data = this.spline_graph_data.bulk_spline_data;
        let individual_spline_data = this.spline_graph_data
          .individual_spline_data;
        bulk_spline_data.forEach((item) => {
          this.series[0].data.push(item.value);
          this.chartOptions.xaxis.categories.push(item.label);
        });
        individual_spline_data.forEach((item) => {
          this.series[1].data.push(item.value);
        });
      }
    },
  },
};
</script>

<style scoped>
.notification-type-card {
  border-radius: 15px;
}
.notification-type-card-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.notification-type-card-sub-title {
  font-size: 16px;
  font-weight: 700;
  /* color: #6d7073; */
  color: #717477;
}
</style>
